<template>
  <b-col cols="12" xl="11"  >
    <b-card id="invoice" class="card border-0 hp-invoice-card"  >
    <!-- i want to show the detail of vehicle here in a good preview -->
 <b-row align-h="center">
  <b-col  style="margin-top: 10px; margin-left:5px; display:flex;text-align:center;justify-content:center;align-item:center;">
    <div  v-for="company in companyes"
v-if="company.id == company_id"
>
<p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
<b><b>Comapny Name :</b></b>
  {{ company.company_name }}
</p>
</div>
  </b-col>
 </b-row>
 <div class="divider"></div>
  <b-row align-h="between">
        <b-col cols="12" lg="12">
          
         
     


          <div class="col-lg-12 col-12" style="display: flex;">
            <b-form-group id="input-group-2" label-for="profile_picture">
              <div style="margin-left: 3px; margin-bottom: 15px;">
                <img :src="'https://api.driveloop.cionax.com/' + image
                      " alt="Profile Picture" width="100px;" height="100px;" style="border-radius: 50%;" />
              </div>

            </b-form-group>
            <div cols="6" style="float: right; margin-top:10px">
              <p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }"> <b>Name:</b>{{ name }}
              </p>
              <p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
              <b>Model:</b>
              {{car_model}}
            </p>
              <p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
                <b>Registration:</b>
                {{ mobile }}
              </p>
            </div>
             <div cols="6" style="float: right; margin-top:10px">
              <p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }"> <b>Color :</b>{{ car_color }}
              </p>
              <p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
              <b>Year:</b>
              {{model_year}}
            </p>
              <p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
                <b>Make:</b>
                {{ car_make }}
              </p>
            </div>
          </div>
        </b-col>
        <!-- <b-col cols="12" md="4" class="text-left text-sm-right hp-print-info">

          <p>Joining Date: {{ joining_date }}</p>
        </b-col> -->
      </b-row>
<div class="divider"></div>
      <b-row>
  <b-col cols="12"
    style="margin-top: 10px; margin-left:5px; display:flex;text-align:center;justify-content:center;align-item:center;">

    <p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Vehicle Type: </b> {{ vehicle_type }}
</p>
 <p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Ownership: </b> {{ ownership }}
</p>

<p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Insurance Company: </b> {{ insurance_company_name }}
</p>

  </b-col>
</b-row><div class="divider"></div>

   <b-row>
  <b-col cols="6"
    >

    <p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Vehicle Report: </b> {{ last_inspection }} - {{ next_inspection}}
</p>
  </b-col>
  <b-col cols="6"
    >
<p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Equipment: </b>
  <span v-for="equipment in select_equipment" :key="equipment.id">{{ equipment }} ,</span>
</p>

  </b-col>
</b-row>
<div class="divider"></div>

   <b-row>
  <b-col cols="6"
    >

    <p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Damage History: </b> {{ damage_history}}
</p>
  </b-col>
  <b-col cols="6"
    >
<p  :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }">
  <b>Documents: </b>
                <div class="col-md-4 col-12">
              <!-- <h4>Upload Document</h4> -->
               <!-- <b-form-group id="input-group-2" label="Registration Number:" label-for="registration_number"> -->
            <div style="display: flex">
              <!-- Display current vehicle images -->
              <div  style="margin-left: 3px; margin-bottom: 15px">
                <img :src="'https://api.driveloop.cionax.com/' + document" alt="document Image"
                  style="max-width: 100px; max-height: 100px" />
            </div>
            </div>
            </div>
 
</p>


  </b-col>
</b-row>

      <div class="divider"></div>
      <b-row>
        <b-col cols="12" style="margin-top: 20px;">
          <b-table-simple small caption-top responsive borderless>
            <b-thead>
              <b-tr class="text-center"> <!-- Add text-center class here -->
                <b-button @click="vehicle" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b>See Vehicle</b></span>
                  </b-button>

                  <!-- <b-button @click="expense" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b> Expense</b></span>
                  </b-button>
                  <b-button @click="driverReports" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b> Report</b></span>
                  </b-button>

                  <b-button @click="driverInvoices" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b> Invoice</b></span>
                  </b-button> -->

                  <b-button @click="editprofile" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b> Edit vehicle</b></span>
                  </b-button>

                <!-- <b-button @click="driverEarningReportlists" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b> Earning Report</b></span>
                  </b-button>

                <b-button @click="driverEfficiencyReports" variant="primary" class="mb-8 mr-8"  style="margin-top: 10px;">
                <span><b> Efficiency Report</b></span>
                  </b-button> -->

                <!-- Repeat the same structure for other buttons -->
                <b-button @click="driverMaintenances" variant="primary" class="mb-8 mr-8" style="margin-top: 10px;">
                <span><b>Vehicle Maintenance</b></span>
                  </b-button>
              </b-tr>
            </b-thead>
          </b-table-simple>
        </b-col>

      </b-row>

      <!-- <div class="divider"></div> -->

    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BLink,
  BButton,
  BCol,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCard,
  BFormGroup,
  BFormSelect

} from "bootstrap-vue";
import axios from "axios";

export default {
  data() {
    return {
      isDataLoaded: false,
     textSize: '16px', // set your desired font size
      marginLeft: '20px', // set your desired margin left
      marginTop: '5px',
      textSizes: '11px',
      display: 'flex',
      //  profile_picture: 'assets/img/logo/car.png', 

      selectedType: "",
      show: true,
      // codeActive: false,
      // codeActiveClass: false,
      isLoading: false,
      // Add Vehicle
      name: "",
      vehicle_company: "",
      description: "",
      car_make: "",
      car_model: "",
       companyes: [],
      company_id: "",
      company_name: "",
      car_color: "",
      car_number: "",
      model_year: "",
      registration_number: "",
      equipment: "",
      image: null,
      document: null,
      // new code
      vehicle_type: "",
      last_inspection: "",
      insurance: "",
      texameter_inspection_date: "",
      next_inspection: "",
      select_equipment: [],
     editVehicle: {
        document: [], // Initialize the array here
      },
      category_of_vehicle: "",
      vendor_name: "",
      insurance_company_name: "",
      car_maintenance_feature: "",
      type_of_insurance: "",
      timing_type: "",
      ownership: "",
      damage_history:"",
      // Car Maintenance
      running: "",
      oil_change: "",
      mileage: "",
      accidental_claim: "",
      other_expense: "",
      editVehicle: {
        image: [], // Initialize the array here
        document: [], // Initialize the array here
      },
      vehicles: [],
    };

  },

  components: {
    BRow,
    BLink,
    BButton,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCard,
    BFormGroup,
BFormSelect
  },

  
  created() {
 axios
      .get("company")
      .then((response) => {
        this.companyes = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.idFromUrl = this.$route.params.id;

   axios
      .get("vehicle")
      .then((response) => {
        this.vehicles = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    // Load the clients data when the component is created
    const userId = this.$route.params.id;
    axios
      .get(`vehicle/${userId}`)
      .then((response) => {
         this.isDataLoaded = true;
        this.editVehicle = response.data.data;
        this.vehicleID = this.editVehicle.id;
        this.name = this.editVehicle.name;
        this.vehicle_company = this.editVehicle.vehicle_company;
        this.description = this.editVehicle.description;
        this.car_make = this.editVehicle.car_make;
        this.car_model = this.editVehicle.car_model;
        this.car_color = this.editVehicle.car_color;
        this.car_number = this.editVehicle.car_number;
        this.model_year = this.editVehicle.model_year;
        this.registration_number = this.editVehicle.registration_number;
        this.equipment = this.editVehicle.equipment;
        this.image = this.editVehicle.image;
        this.document = this.editVehicle.document;
        this.ownership = this.editVehicle.ownership;
        this.damage_history = this.editVehicle.damage_history;
        this.vehicle_type = this.editVehicle.vehicle_type;
        this.last_inspection = this.editVehicle.last_inspection;
        this.insurance = this.editVehicle.insurance;
        this.texameter_inspection_date =
        this.editVehicle.texameter_inspection_date;
        this.next_inspection = this.editVehicle.next_inspection;
          this.select_equipment = this.editVehicle.select_equipment.split(',');
        this.type_of_insurance = this.editVehicle.type_of_insurance;
        this.timing_type = this.editVehicle.timing_type;
        this.vendor_name = this.editVehicle.vendor_name;
        this.insurance_company_name = this.editVehicle.insurance_company_name;
        this.car_maintenance_feature = this.editVehicle.car_maintenance_feature;
        this.running = this.editVehicle.running;
        this.oil_change = this.editVehicle.oil_change;
        this.mileage = this.editVehicle.mileage;
        this.accidental_claim = this.editVehicle.accidental_claim;
        this.other_expense = this.editVehicle.other_expense;
        this.company_id = this.editVehicle.company_id;
        this.company.company_name = this.editVehicle.company.company_name;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },

  methods: {
    showMsgBoxOne() {
      debugger;
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
      // debugger
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    onProfilePictureChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Set the selected file to the data property
        this.profile_picture = file;
      }
    },

//     vehicle() {
//       this.$router.push({ name: 'vehicleID', params: { id: this.vehicle_id } });
// },
    vehicle() {
     
  this.$router.push({ name: 'viewVehicle', params: { id: this.vehicleID } });

},
expense() {
      this.$router.push({ name: 'droverExpnse', params: { id: this.idFromUrl } });
},

driverReports() {
      this.$router.push({ name: 'driverReport', params: { id: this.idFromUrl } });
},

driverInvoices() {
      this.$router.push({ name: 'driverInvoice', params: { id: this.idFromUrl } });
},

driverEfficiencyReports() {
      this.$router.push({ name: 'driverEfficiencyReport', params: { id: this.idFromUrl } });
},

driverMaintenances() {
  // console.log('vehicle_id', this.vehicleID);
      this.$router.push({ name: 'driverMaintenance', params: { id: this.vehicleID } });
},

editprofile() {
      this.$router.push({ name: 'editVehicle', params: { id: this.vehicleID } });
},


driverEarningReportlists() {
    this.$router.push({
        name: 'driverEarningReportlist',
        params: {
            name: this.name,
            last_name: this.last_name
        }
    });
},

    // codeClick() {
    //   this.codeActive = !this.codeActive;
    // },

    saveOwnCar() {
      this.showModal = false;
    },
  },
};
</script>